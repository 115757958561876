import React from "react";
import { Col, Row, Tabs } from "antd";
import servicesData from "../../pages/Home/servicesData";
import * as icons from "../../assets/icons";
import styles from "./home.module.css";
import { Link } from "react-router-dom";

function getChild(data) {
  return (
    <Row gutter={[20, 20]} justify="center">
      {data.map(({ title, description, icon, to }, key) => {
        const Icon = icons[icon] || icons["WindowIcon"];
        return (
          <Col
            key={key}
            {...{ xs: 24, sm: 12, md: 8, lg: 8, xl: 8 }}
            flex="auto"
            style={{ height: "auto!important" }}
            data-aos="fade-up"
            data-aos-delay={key * 100}
          >
            <Link to={to}>
              <div className={styles.card}>
                <Icon width={38} height={38} />
                <h3>{title}</h3>
                <p>{description}</p>
              </div>
            </Link>
          </Col>
        );
      })}
    </Row>
  );
}
const items = [
  {
    key: "1",
    label: "IT Services",
    children: getChild(servicesData.itServices),
  },
  {
    key: "2",
    label: "Solar Services",
    children: getChild(servicesData.solarServices),
  },
];

function Services() {
  return (
    <div className={styles.servicesContainer} id="services">
      <h4>What We Do</h4>
      <h2>Services That Help You Grow.</h2>
      <Tabs items={items} />
    </div>
  );
}

export default Services;
