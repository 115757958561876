import React from "react";
import { LinkedinIcon, YoutubeIcon } from "../../assets/icons";
import { Col, Row } from "antd";
import styles from "./index.module.css";
function SocialIcons({ justify }) {
  return (
    <Row
      justify={{
        lg: justify || "end",
        sm: justify || "end",
        xs: justify || "end",
      }}
      className={styles.socialIcons}
      gutter={20}
    >
      {/* <Col>
        <FaceBookIcon />
      </Col> */}
      <Col>
        <LinkedinIcon />
      </Col>
      {/* <Col>
        <TwitterIcon />
      </Col> */}
      <Col>
        <YoutubeIcon />
      </Col>
      {/* <Col>
        <InstagramIcon />
      </Col> */}
    </Row>
  );
}

export default SocialIcons;
