import React from "react";
import BodyContainer from "../../components/containers/BodyContainer";
import styles from "./services.module.css";
import { Col, Row } from "antd";
import { tele, teleBg } from "../../assets";
import Card from "../../components/cards/Card";

function TeleCommunication() {
  return (
    <>
      <BodyContainer bgImage={teleBg}>
        <h1>
          <span>Telecommunications</span> Services
        </h1>
      </BodyContainer>

      <div className={styles.appDevContainer} style={{ padding: "60px 0 10px" }}>
        <h4>Our Services</h4>
        <h1>Telecommunications Service</h1>

        <Row justify={"center"} style={{ margin: "10px 0" }}>
          <Col xs={24} md={18}>
            <h5>
              We at TriventCad make great efforts to bring digital to every
              person and organization. We help businesses stay connected to the
              digital world and that’s by providing cutting-edge
              telecommunication services. Not only do we enhance communication
              experience, but with our innovative solution, we help you to
              elevate your communication, business, and cooperation.
            </h5>
          </Col>
        </Row>
      </div>

      <Card
        className="fitImage"
        gutter={[34, 34]}
        Image={
          <Col xs={24} sm={24} md={12} lg={12}>
            <img src={tele} alt="about1" />
          </Col>
        }
        Body={
          <Col xs={24} lg={12}>
            <h4>Our Services</h4>
            <h1>What are Telecommunication Services</h1>
            <ul>
              <li>
                Telecommunication services are the spine of modern connectivity,
                allowing flawless national or even global communications.
                Ranging from voice calls to high internet services, these
                services surround a broad range of technologies. Mobile networks
                allow endless coverage, making it convenient for communications.
              </li>
              <li>
                Landline services still play a crucial role for businesses and
                households, providing accuracy, security, and stability.
                Internet service providers allow broadband connectivity,
                strengthening digital interactions and inter-online activities.
                Also, telecommunication services support new or rising
                technologies like IOT or the Internet of Things, ensuring that
                interlinked devices can communicate effortlessly.
              </li>
            </ul>
          </Col>
        }
      />
    </>
  );
}

export default TeleCommunication;
