import React from "react";
import styles from "./card.module.css";
import { AIStart } from "../../assets/icons";
function SecondaryCard({ title, content }) {
  return (
    <div className={styles.secondaryCard}>
      <AIStart />
      <h2>{title}</h2>
      {content?.length && content.map((c, index) => <h5 key={index}>{c}</h5>)}
    </div>
  );
}

export default SecondaryCard;
