import React from "react";
import BodyContainer from "../../components/containers/BodyContainer";
import styles from "./services.module.css";
import { Col, Row } from "antd";
import { database1, database2, databaseBg, dataimg,  } from "../../assets";
import Card from "../../components/cards/Card";
import SecondaryCard from "../../components/cards/SecondaryCard";
function Database(props) {
  return (
    <>
      <BodyContainer bgImage={databaseBg}>
        <h1>
          <span>Database</span> Design & Development
        </h1>
      </BodyContainer>

      <Card
        className="fitImage"
        gutter={[34, 34]}
        Image={
          <Col xs={24} sm={24} md={12} lg={10}>
            <img src={database1} alt="about1" />
          </Col>
        }
        Body={
          <Col xs={24} lg={12}>
            <h4>Our Services</h4>
            <h1>About Database Design & Development Services</h1>
            <ul>
              <li>
                With our cutting-edge database design and deployment services
                crafted for IT companies, explore the power of efficiency and
                innovation. From accurate needs to seamless data transfer and
                powerful backup solutions, we ensure your data framework is
                updated for success, allowing your IT operations to flourish in
                the current dynamic landscape.
              </li>
            </ul>
          </Col>
        }
      />

      <div className={styles.appDevContainer} style={{ padding: "0px 0 10px", display:"none" }}>
        <h4>Database Design & Development</h4>
        <h1>Database Development Solutions for</h1>
        <h1>Your Business</h1>
        <Row justify={"center"} style={{ margin: "10px 0" }}>
          <Col xs={24} md={18}>
            <h5>
              We are known for our app development expertise. With a great
              dedication and precision to innovation, our team crafts
              applications as per clients' requirements and needs with utmost
              precision like, a great user interface (intuitive UI), clear and
              organized content without any complexity, with great presentation,
              hierarchy and performance.
            </h5>
          </Col>
        </Row>

        {/* <Row justify={"center"} style={{ margin: "10px 0" }}>
          <Col xs={24} md={18}>
            <h5>
              Front-end developers primarily work on the user interface and
              experience, utilizing HTML, CSS, and JavaScript to structure web
              pages, enhance visual aesthetics, and add interactive elements.
              Meanwhile, back-end developers focus on server-side programming,
              database management, and server configuration, using languages
              like JavaScript (Node.js), Python, Ruby, PHP, and Java.
            </h5>
          </Col>
        </Row> */}
        <Row justify={"center"} style={{ margin: "30px 0" }}>
          <Col xs={22} md={18}>
            <img src={database2} className={styles.app} alt="app-development" />
          </Col>
        </Row>
      </div>

      <div className={styles.cardContainer}>
        <div className="w-layout-grid feature-cards-grid grid-halves two-columns">
          <div className="sticky-wrap">
            <div className="centered sticky-block">
              <h1>What Database Design & Development Services We Providing</h1>
              <h6>
              We offer comprehensive database design and development services, including consulting, architecture, implementation, optimization, migration, security, and ongoing support.
              </h6>
              <img src={dataimg} className={styles.dataimg} alt="app-development" />
            </div>
          </div>
          <div className="stick-content-wrap">
            <div className="services sticky-feature-card">
              <SecondaryCard
                title="Database Deployment Services"
                content={[
                  "Lift Up your business with our all-inclusive database deployment services. Our team of experts excels in every field of database management, from needs analysis and data modeling to seamless transfer and execution. With a central focus on scalability, security, and effectiveness, we deliver optimum solutions that will drive productivity and growth to your organization.",
                ]}
              />
              <SecondaryCard
                title="Requirements Analysis"
                content={[
                  "Know the proper analytic factors for your IT needs with our detailed analysis processes. We work deeply with our team to know about your unique challenges and objectives, creating database solutions that will contrast with your business motive, for maximum profitability and scalability.",
                ]}
              />
              <SecondaryCard
                title="Data Modelling"
                content={[
                  "Update your framework with our competitive data modeling techniques. Our team of experts crafts logical data representations that sleek information flow, intensify data integrity, and improve system efficiency, allowing your IT systems to operate at their optimum, which, in turn, supports your business growth.",
                ]}
              />
              <SecondaryCard
                title="Schema Design"
                content={[
                  "Create a rigid base for your daily operations with our strategic schema design services. We craft powerful database schema designs that encourage elasticity, scalability, and data integrity, ensuring flawless integration with your existing IT environment for future expansion and innovation.",
                ]}
              />
              <SecondaryCard
                title="Data Migration"
                content={[
                  "Shift smoothly to the new IT ecosystem with our flawless data migration services. Our precise approach decreases downtime and disturbance, ensuring information trustworthiness and consistency all through the movement processes, empowering your IT tasks to be predictable while utilizing the benefits of improved systems.",
                ]}
              />
              <SecondaryCard
                title="Data Implementation"
                content={[
                  "Helps the worth of your IT speculations with our master information execution administrations. Our group of specialists arranges and refreshes information bases to satisfy your particular guidelines and requirements, guaranteeing flawless combination and ideal execution, enabling your IT frameworks to uphold your business thought processes effectively.",
                ]}
              />
              <SecondaryCard
                title="Backup and Recovery Planning"
                content={[
                  "Safeguard your crucial data with our exhaustive backup and recovery planning. We create and modify systems to safeguard against data losses and limit personal time. With best-in-class technologies and industry best practices to ensure the steady availability and reliability of your IT systems.",
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Database;
