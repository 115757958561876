import React from "react";
import { BodyContainer } from "../../components";
import styles from "./services.module.css";
import { Col, Row } from "antd";
import { ai2, databaseBg, standimg } from "../../assets";
import Card from "../../components/cards/Card";
import PrimaryCard from "../../components/cards/PrimaryCard";
import SecondaryCard from "../../components/cards/SecondaryCard";

function AI() {
  return (
    <>
      <BodyContainer bgImage={databaseBg}>
        <h1>
          <span>Artificial</span> Intelligence
        </h1>
      </BodyContainer>

      <Card
        className="fitImage"
        gutter={[34, 34]}
        Image={
          <Col xs={24} sm={24} md={12} lg={10}>
            <img src={ai2} alt="about1" />
          </Col>
        }
        Body={
          <Col xs={24} lg={12}>
            <h4>Our Services</h4>
            <h1>Creative UI/UX design solutions</h1>
            <ul>
              <li>
                AI services hold the most crucial space for innovation,
                transforming industries with advanced algorithms and machine
                learning tactics and techniques. At our company, we harness the
                ability of Artificial Intelligence to direct business forward,
                providing a range of progressive solutions to meet all types of
                requirements.
              </li>
            </ul>
          </Col>
        }
      />

      <div className={styles.cardContainer}>
        <div className="w-layout-grid feature-cards-grid grid-halves two-columns">
          <div className="sticky-wrap">
            <div className="centered sticky-block">
              <h1>Stand out in your industry</h1>
              <h2>
                with cutting-edge digital products that have a lasting impact.
              </h2>
              <h6>
                Clearly outline the goal of the generative model. Do you want it
                to generate text, images, music, or something else?
                Understanding the desired output is crucial.
              </h6>

              <img src={standimg} className={styles.standimg} alt="app-development" />
              
            </div>
          </div>
          <div className="stick-content-wrap">
            <div className="services sticky-feature-card">
              <SecondaryCard
                title="1. Generative AI Model Development"
                content={[
                  "Our skilled team of experts specializes in crafting competitive AI models that boost the limit of creativity and innovation. With an in-depth understanding of advanced algorithms, we can craft models that generate peak-quality content among various business domains.",
                ]}
              />
              <SecondaryCard
                title="2. Generative Model Replication"
                content={[
                  "we are not only specialized for our innovations and progressive solutions, but we also excel in replicating generative models with accuracy and efficiency, regardless of whether cloning existing models or creating new ones. Our expert teams ensure the original model's quality, effectiveness, and integrity.",
                ]}
              />
              <SecondaryCard
                title="3. Model integration and deployment"
                content={[
                  "we are not only specialized for our innovations and progressive solutions, but we also excel in replicating generative models with accuracy and efficiency, regardless of whether cloning existing models or creating new ones. Our expert teams ensure the original model's quality, effectiveness, and integrity.",
                ]}
              />
              <SecondaryCard
                title="4. AI Model fine-tuning"
                content={[
                  "Calibrating AI models to perfection is necessary for achieving optimum performance. Our skilled specialists employ technical techniques and rigid testing methodologies to refine models. ",
                ]}
              />
              <SecondaryCard
                title="5. AI Model Architecting"
                content={[
                  "Creating adaptable structures is central to the outcome of computer-based intelligence drives. Attracting our profound skill in man-made intelligence model engineering, we plan arrangements that are proficient, versatile, and lined up with business goals, preparing for maintainable development and advancement.",
                ]}
              />
              <SecondaryCard
                title="6. Deep Learning"
                content={[
                  "Explore our realm of deep learning with our broad services designed to unleash the potential of neural networks, from image recognition to natural language processing. We enhance the potential for businesses!",
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.serviceContainer + " " + styles.appDev}>
        <h1>AI Services We Offer</h1>
        <Row gutter={20} className={styles.web}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="Deep Learning"
              content={[
                "Explore our realm of deep learning with our broad services designed to unleash the potential of neural networks, from image recognition to natural language processing. We enhance the potential for businesses!",
              ]}
              list={true}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="Sentimental Analysis"
              content={[
                "Acquire significant experience in analyzing client conclusions and market patterns with our high-level opinion examination administrations. Utilizing cutting-edge calculations, we investigate printed information to perceive feelings, empowering organizations to pursue information-driven choices and upgrade client encounters. ",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <PrimaryCard
              title="Chatbot Development"
              content={[
                "We have expertise in crafting chatbot development services to understand your customers' queries better than humans. We create smart chatbots that not only chat with the customers but also can navigate them to the optimal solutions. It helps to deliver personalized user experience and support and drive engagement. ",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <PrimaryCard
              title="AI Development Services:"
              content={[
                "From conceptualization to organization, we offer start-to-finish computer-based intelligence advancement administrations custom-made to your particular necessities. Whether you're hoping to incorporate custom artificial intelligence arrangements or coordinate simulated intelligence capacities into existing frameworks, our skilled and creative group is here to direct you constantly.",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <PrimaryCard
              title="Data Services"
              content={[
                "We provide comprehensive data services. From data extracting to data analysis, we help businesses equip themselves with the full potential of their data assets to lead optimum decision-making to gain a competitive advantage.  ",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <PrimaryCard
              title="AI Integration Services"
              content={[
                "Flawlessly coordinate AI intelligence abilities into your current frameworks with our specific reconciliation administrations. Whether you're hoping to increase work processes with AI intelligence-fueled mechanization or improve applications with shrewd elements, we give custom-fitted arrangements that amplify proficiency and drive your business esteem.",
              ]}
            />
          </Col>
        </Row>
      </div>

       
    </>
  );
}

export default AI;
