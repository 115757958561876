import React from "react";
import styles from "./footer.module.css";
import { Col, Row } from "antd";
import { logo } from "../../assets";
import SocialIcons from "../social";
import { CallIcon, EmailIcon } from "../../assets/icons";
import { Link, NavLink } from "react-router-dom";
function Footer() {
  const emailIconProps = {
    width: 24,
    height: 24,
    stroke: "#0ac79a",
    fill: "#0ac79a",
    strokeWidth: 0.1,
  };

  return (
    <div className={styles.container}>
      <Row className={styles.footerContainer} gutter={[0, 10]}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <Row justify={"start"}>
            <Col>
              <Link to="/">
                <img src={logo} style={{ width: 220 }} alt="logo" />
              </Link>
              <h5>
                We have been most trusted CAD designing and software development
                partner since 7+ years.
              </h5>
              <SocialIcons justify="start" />
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={12} md={8} lg={8}>
          <Row
            justify={{ xs: "start", sm: "start", md: "center" }}
            className={styles.quickLinks}
          >
            <Col>
              <h2 style={{ marginBottom: 5 }}>Quick links</h2>
              <NavLink to="/">
                <h6>Home</h6>
              </NavLink>
              <a href="/#services">
                <h6>Services</h6>
              </a>
              <NavLink to="about">
                <h6>About Us</h6>
              </NavLink>
              <NavLink to="contactus">
                <h6>Contact Us</h6>
              </NavLink>
            </Col>
          </Row>
        </Col>

        <Col xs={12} sm={12} md={8} lg={8}>
          <Row justify={{ xs: "end", sm: "start", md: "center" }}>
            <Col>
              <h2>Get In Touch</h2>
              <Row gutter={10} style={{ marginTop: 10 }}>
                <Col>
                  <CallIcon />
                </Col>
                <Col>
                  <h4 style={{ textAlign: "left", fontWeight: 600 }}>
                    Call Us
                  </h4>
                  <h6 style={{ fontWeight: 400, marginTop: 5 }}>
                    +91 981 779 1221
                  </h6>
                </Col>
              </Row>
              <Row gutter={10} style={{ marginTop: 20 }}>
                <Col>
                  <EmailIcon {...emailIconProps} />
                </Col>
                <Col>
                  <h4 style={{ textAlign: "left", fontWeight: 600 }}>
                    Email Us
                  </h4>
                  <h6 style={{ fontWeight: 400, marginTop: 5 }}>
                    info@triventcad.com
                  </h6>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className={styles.copyright}>
        <h5 style={{ color: "#CCCCCC", fontWeight: 400 }}>
          © 2024 ALL RIGHTS RESERVED By Triventcad
        </h5>
      </div>
    </div>
  );
}

export default Footer;
