import React from "react";
import { Result } from "antd";  
function CADConversion(props) {
  return (
    <>
       <div className="notfound">
      <Result
        status="404"
        title="This Service Page is Coming Soon!"
        subTitle=""
      />
      
    </div>

     
 
    </>
  );
}

export default CADConversion;
