import { Route, Routes, useLocation } from "react-router-dom";
import { Home, About } from "./pages";
import { ContactUs, Services } from "./pages";
import { Header, Footer } from "./components";
import styles from "./app.module.css";
import { ConfigProvider } from "antd";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import NotFound from "./pages/NotFound/NotFound";

function App() {
  const location = useLocation();
  const hashValue = location.hash;
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  useEffect(() => {
    // Scroll to the target section on page load
    setTimeout(() => {
      if (hashValue) {
        const targetSection = document.getElementById(hashValue.split("#")[1]);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 500);
  }, [hashValue]);
  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#0ac79a" } }}>
      <div className={styles.container}>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contactUs" element={<ContactUs />} />
          <Route path="services/*" element={<Services />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </ConfigProvider>
  );
}

export default App;
