import React from "react";
import BodyContainer from "../../components/containers/BodyContainer";
import styles from "./services.module.css";
import { Col, Row } from "antd";
import {
  angular,
  laravel,
  mongo,
  node,
  php,
  web,
  webBg,
  wordpress,
} from "../../assets";
import Card from "../../components/cards/Card";
import PrimaryCard from "../../components/cards/PrimaryCard";
function WebDevelopment(props) {
  return (
    <>
      <BodyContainer bgImage={webBg}>
        <h1>
          <span>Web</span> Development Services
        </h1>
      </BodyContainer>

      <Card
        className="fitImage"
        gutter={[34, 34]}
        Image={
          <Col xs={24} sm={24} md={12} lg={10}>
            <img src={web} alt="about1" />
          </Col>
        }
        Body={
          <Col xs={24} lg={12}>
            <h4>Our Services</h4>
            <h1>Our Web Development Services</h1>
            <ul>
              <li>
                Let's work together to translate your business problems into
                technology solutions. Get the benefit of a stunning,
                user-friendly, and cutting-edge website and unleash your digital
                presence.
              </li>
              <li>
                Our team of 20+ skilled web developers and designers will help
                you craft elegant and functional websites leveraging the latest
                technology and trends.
              </li>
            </ul>
          </Col>
        }
      />

      <div className={styles.serviceContainer}>
        <h1>
          Crafting digital experiences that captivate <br /> and inspire users globally
        </h1>
        <h5>
          Web development is a multifaceted discipline focused on creating and
          maintaining websites and web applications. It encompasses various
          roles and skill sets, including front-end development, back-end
          development, and full-stack development. Overall, web development is a
          dynamic field at the forefront of digital innovation, continuously
          evolving to meet the demands of modern technology and user
          expectations.
        </h5>

        <Row gutter={20} className={styles.web}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="Custom Web Development"
              content={[
                "Enhance your online presence with the help of our competitive web development solutions. From compact designs to flawless functionality, we create websites that overtake your unique expectations, keeping user experience and business development in mind.",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="E-Commerce Web Development"
              content={[
                "Start your online store with courage! We create e-commerce web development services that allow businesses and startups to make their place in online market segments. With unique and creative features as well as simple-to-use and eye-catching interfaces, we craft a broad range of platforms that, in turn, lead to sales, traffic, and customer satisfaction.",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <PrimaryCard
              title="Web Applications"
              content={[
                "Transform your business operations with the help of our creative web app solutions. Our team of creative experts creates measurable and secured web applications that meet your expectations.",
              ]}
            />
          </Col>
        </Row>

        <Row gutter={20} className={styles.webtop}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="CMS Platform"
              content={[
                "Take command of your digital content smoothly and efficiently with our powerful CMS platform solutions. Whether it's content creation or flawless publishing, with the help of our CMS platform, you can easily manage everything on your website. With our customized CMS solutions, you can enhance your workflows."
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="Static Web Service"
              content={[
                "Explore minimalism and accuracy with our static web service solutions. Great to showcase your brand portfolio or image, this service helps with the faster website loading speed and also provides a smooth navigation guide. Establish your digital presence with our sleek static web service solutions.",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <PrimaryCard
              title="DevOps as a Service"
              content={[
                "Stand ahead in competition with our DevOps services designed to your organization's needs. With the help of our DevOps services, businesses can enhance their work process and focus on core operations. From continual merging to automated deployment, we trim the complex processes and enhance collaboration, which in turn delivershigh-quality software for reliability and effectiveness to your team.",
              ]}
            />
          </Col>
        </Row>


      </div>

      <div className={styles.footer}>
        <h1>We Work On Technologies</h1>
        <h2>We are committed to provide our customers with exceptional.</h2>

        <div className={styles.tech}>
          <img src={php} alt="php" />
          <img src={mongo} alt="mongo" />
          <img src={laravel} alt="laravel" />
          <img src={wordpress} alt="mongo" />
          <img src={angular} alt="angular" />
          <img src={node} alt="node" />
        </div>
      </div>
    </>
  );
}

export default WebDevelopment;
