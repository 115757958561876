import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./navLinks.module.css";
import { Col, Divider, Row, Tooltip } from "antd";
import { ChevronIcon } from "../../assets/icons";
import servicesData from "../../pages/Home/servicesData";
import { navImage1, navImage2 } from "../../assets";

const itServiceList = servicesData.itServices.map(({ title, to }, key) => {
  return (
    <Col span={12} key={key}>
      <NavLink to={to} className={styles.dropdownItem}>
        <h6>&#x2022; {title}</h6>
      </NavLink>
    </Col>
  );
});

const solarServiceList = servicesData.solarServices.map(
  ({ title, to }, key) => {
    return (
      <Col span={12} key={key}>
        <NavLink to={to} className={styles.dropdownItem}>
          <h6>&#x2022; {title}</h6>
        </NavLink>
      </Col>
    );
  }
);

function NavLinks({ direction }) {
  const navClass = `${styles.navLinks} ${
    direction === "vertical" ? styles.navLinksVertical : ""
  }`;

  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(!open);
  }
  return (
    <div className={navClass}>
      <NavLink to="/">Home</NavLink>
      <Tooltip
        open={open}
        onOpenChange={handleOpen}
        title={
          <Row className={styles.navTitle}>
            <Col span={11}>
              <h3>IT Services</h3>
              <Row gutter={[0, 20]}>
                <Col>
                  <img src={navImage2} style={{ width: "100%" }} alt="" />
                </Col>
                <Col>
                  <Row onClick={handleOpen} gutter={[5, 8]}>
                    {itServiceList}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={2}>
              <Row justify={"center"} style={{ height: "100%" }}>
                <Col>
                  <Divider
                    type="vertical"
                    style={{
                      height: "100%",
                      borderLeft: "1px solid var(--divider-light)",
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={11}>
              <h3>Solar Services</h3>
              <Row gutter={[0, 20]}>
                <Col>
                  <img src={navImage1} style={{ width: "100%" }} alt="" />
                </Col>
                <Col>
                  <Row onClick={handleOpen} gutter={[5, 8]}>
                    {solarServiceList}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        placement="bottom"
        align={{ points: ["tl", "bl"], offset: [-500, 10] }}
        color={"#fff"}
      >
        <Row gutter={5}>
          <Col>
            <a
              href="/"
              onClick={(e) => e.preventDefault()}
              style={{ marginRight: 0 }}
            >
              Services
            </a>
          </Col>
          <Col style={{ marginTop: -2 }}>
            <ChevronIcon />
          </Col>
        </Row>
      </Tooltip>
      <NavLink to="about">About</NavLink>
      <NavLink to="contactUs">Contact Us</NavLink>
    </div>
  );
}

export default NavLinks;
