import React from "react";
import { Col, Row } from "antd";

// Assets
import { EmailIcon } from "../../assets/icons";
import { logo } from "../../assets";

// Styles
import styles from "./index.module.css";
import SocialIcons from "../social";
import NavLinks from "../navLinks";
import MobileNavLinks from "./MobileNavLinks";
import { Link } from "react-router-dom";
function Header() {
  return (
    <header className={styles.header}>
      <div className={styles.socialContainer}>
        <Row>
          <Col>
            <Row
              align="middle"
              justify={{ lg: "start", sm: "center", xs: "start" }}
              gutter={6}
            >
              <Col>
                <EmailIcon style={{ marginTop: 3 }} />
              </Col>
              <Col>
                <h5>Email: info@triventcad.com </h5>
              </Col>
            </Row>
          </Col>
          <Col flex={1}>
            <SocialIcons />
          </Col>
        </Row>
      </div>
      <div className={styles.navBar}>
        <Row align="middle" gutter={[10, 10]}>
          <Col flex={1}>
            <Link to="/">
              <Row
                justify={{ lg: "start", md: "start", xs: "start", sm: "start" }}
              >
                <img src={logo} style={{ width: 220 }} alt="logo" />
              </Row>
            </Link>
          </Col>
          <Col flex={1} className={styles.desktopMenu}>
            <Row justify={{ lg: "end", sm: "center", xs: "center" }}>
              <NavLinks />
            </Row>
          </Col>
          <MobileNavLinks />
        </Row>
      </div>
    </header>
  );
}

export default Header;
