import React from "react";
import styles from "./button.module.css";
function PrimaryButton({ title, ...props }) {
  return (
    <button className={styles.primaryBtn} {...props}>
      {title}
    </button>
  );
}

export default PrimaryButton;
