import React from "react";
import styles from "./card.module.css";
function PrimaryCard({ title, content, list = false }) {
  return (
    <div className={styles.primaryCard}>
      <h2>{title}</h2>
      {content?.length &&
        content.map((c, index) => (
          <h5 key={index} className={styles.list}>
            {list ? <span>&#8226; </span> : null}
            {c}
          </h5>
        ))}
    </div>
  );
}

export default PrimaryCard;
