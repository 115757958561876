import React, { useState } from "react";
import { Form, Input, Row, Col, notification, Spin, Modal } from "antd";
import { PrimaryButton } from "../../components";
import emailjs from "@emailjs/browser";
import { useForm } from "antd/es/form/Form";
import styles from "./contact.module.css";
import { email } from "../../assets";

const ContactForm = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await emailjs.send("service_2slda7f", "template_yxvqmog", values, {
        publicKey: "7M97nSjN_f8Ila2b7",
      });
      setThankYou(true);
      form.resetFields();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error",
        description: error?.message || error?.text,
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^(\+\d{1,4}[-\s]?)?\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please enter a valid phone number!"));
  };

  return (
    <Form
      layout="vertical"
      name="contact-form"
      style={{ marginTop: 30 }}
      onFinish={handleSubmit}
      form={form}
    >
      <Modal
        open={thankYou}
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setThankYou(!thankYou)}
      >
        <div className={styles.container}>
          <img src={email} alt="email" className={styles.email} />
          <h1 className={styles.title}>Thank You!</h1>
          <p className={styles.message}>
            We have received your request and will get back to you shortly.
          </p>

          <div className={styles.closeBtn}>
            <PrimaryButton
              title="Close"
              onClick={() => setThankYou(!thankYou)}
            />
          </div>
        </div>
      </Modal>
      {loading ? (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      ) : null}
      <Row gutter={20}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: "First Name is required!",
              },
            ]}
          >
            <Input placeholder="Enter your first name" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Last Name is required!",
              },
            ]}
          >
            <Input placeholder="Enter your last name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
              {
                required: true,
                message: "Email is required!",
              },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Phone number is required!",
              },
              {
                validator: validatePhoneNumber,
              },
            ]}
          >
            <Input placeholder="Enter your phone number" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="message"
        label="Message"
        rules={[
          {
            required: true,
            message: "Message is required!",
          },
        ]}
      >
        <Input.TextArea rows={6} placeholder="Type your message here" />
      </Form.Item>
      <Form.Item>
        <Row>
          <Col xs={8} sm={8} md={8} lg={7} xl={7}>
            <PrimaryButton title="Send" htmltype="submit" />
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default ContactForm;
