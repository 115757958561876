import React from "react";

import { Result } from "antd";
import { Link } from "react-router-dom";
import { PrimaryButton } from "../../components";

function NotFound(props) {
  return (
    <div className="notfound">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
      <Link to="/">
        <PrimaryButton title="Back Home" />
      </Link>
    </div>
  );
}

export default NotFound;
