import React from "react";
import { Route, Routes } from "react-router-dom";
import WebDevelopment from "./WebDevelopment";
import AppDevelopment from "./AppDevelopment";
import UXUI from "./UXUI";
import AI from "./AI";
import TeleCommunication from "./TeleCommunication";
import Database from "./Database";
import SolarServices from "./SolarServices";
 

import NotFound from "../NotFound/NotFound";
import CADConversion from "./CADConversion";

function Services() {
  return (
    <Routes>
      <Route path="/web-development" element={<WebDevelopment />} />
      <Route path="/app-development" element={<AppDevelopment />} />
      <Route path="/ux-ui" element={<UXUI />} />
      <Route path="/ai" element={<AI />} />
      <Route path="/tele-communication" element={<TeleCommunication />} />
      <Route path="/database" element={<Database />} />
      <Route path="/solar-services" element={<SolarServices />} />
      <Route path="/cad-conversion" element={<CADConversion />} />
     

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Services;
