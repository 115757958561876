import React from "react";
import { Row } from "antd";
import styles from "./card.module.css";
function Card({ gutter, Image, Body, reverse, className }) {
  const _class = `${styles.card} ${className ? styles[className] : ""}`;
  const reverseClass = `${reverse ? styles.reverse : ""}`;
  return (
    <div className={_class}>
      <Row gutter={gutter || [8, 24]} className={reverseClass} align={"middle"}>
        {Image}
        {Body}
      </Row>
    </div>
  );
}

export default Card;
