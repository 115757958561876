import React from "react";
import BodyContainer from "../../components/containers/BodyContainer";
import styles from "./services.module.css";
import { Col, Row } from "antd";
import { app, appDevBg } from "../../assets";
import PrimaryCard from "../../components/cards/PrimaryCard";
function AppDevelopment(props) {
  return (
    <>
      <BodyContainer bgImage={appDevBg}>
        <h1>
          <span>App</span> Development Services
        </h1>
      </BodyContainer>

      <div className={styles.appDevContainer}>
        <h4>App Development</h4>
        <h1>Our Web Development Services</h1>

        <Row justify={"center"} style={{ margin: "10px 0" }}>
          <Col xs={24} md={18}>
            <h5>
              We are known for our app development expertise. With a great
              dedication and precision to innovation, our team crafts
              applications as per clients' requirements and needs with utmost
              precision like, a great user interface (intuitive UI), clear and
              organized content without any complexity, with great presentation,
              hierarchy and performance.
            </h5>
          </Col>
        </Row>

        {/* <Row justify={"center"} style={{ margin: "10px 0" }}>
          <Col xs={24} md={18}>
            <h5>
              Front-end developers primarily work on the user interface and
              experience, utilizing HTML, CSS, and JavaScript to structure web
              pages, enhance visual aesthetics, and add interactive elements.
              Meanwhile, back-end developers focus on server-side programming,
              database management, and server configuration, using languages
              like JavaScript (Node.js), Python, Ruby, PHP, and Java.
            </h5>
          </Col>
        </Row> */}
        <Row justify={"center"} style={{ margin: "30px 0" }}>
          <Col xs={22} md={18}>
            <img src={app} className={styles.app} alt="app-development" />
          </Col>
        </Row>
      </div>

      <div className={styles.serviceContainer + " " + styles.appDev}>
        <h1>Custom Mobile App Development</h1>
        <h5>
          Custom mobile app development refers to the process of creating mobile
          applications tailored to meet the specific needs and requirements of a
          business or individual client. Unlike off-the-shelf or pre-built apps,
          custom mobile apps are built from scratch, allowing for complete
          customization in terms of features, design, and functionality.
        </h5>
        <Row gutter={20} className={styles.web}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="Android App Development"
              content={[
                "Our team of experts influences up-to-date technologies and industry practices to build vital Android applications that engage users and drive traffic and engagement. Tell us your requirements whether it's a consumer-facing application or a technical application we will provide you with the best!",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="IOS app Development"
              content={[
                "We also excel in iOS application development driving the complete potential of Apple’s environment. Our technically skilled and creative team combines creativity with technical expertise to build the highly competitive iOS application to stand out in the market segment which not only boosts businesses but also earns the trust of users. Lift up your brand with XYZ IT company now!",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <PrimaryCard
              title="React Native App Development"
              content={[
                "Our well-organized team of developers are committed to deliver  secure, high-quality, and scalable apps and websites to meet the overall demand of your’s. We invest in research and development to offer you the latest product optimized with  performance, user experience, and functionality.",
              ]}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AppDevelopment;
