import React from "react";
import { BodyContainer } from "../../components";
import styles from "./services.module.css";
import { Col, Row } from "antd";
import { uxBg, web, uiuximg } from "../../assets";
import Card from "../../components/cards/Card";
import PrimaryCard from "../../components/cards/PrimaryCard";

function UXUI() {
  return (
    <>
      <BodyContainer bgImage={uxBg}>
        <h1>
          <span> UI/UX</span> Design Services
        </h1>
      </BodyContainer>

      <div className={styles.appDevContainer} style={{ padding: "60px 0 10px" }}>
        <h4>UI/UX design</h4>
        <h1>UI/UX design and Visual Design Services</h1>

        <Row justify={"center"} style={{ margin: "10px 0" }}>
          <Col xs={24} md={18}>
            <h5>
              Beyond pretty websites and decent apps, our services stretch far
              up to UI/UX design and visual design. Our creative team is driven
              by a passion for creating stunning, detail-oriented designs. As
              the leading agency, we help you fulfill your project's diverse
              priorities, targets, and objectives. Explore your creativity with
              our unique ideas.
            </h5>
          </Col>
        </Row>
      </div>

      <Card 
        className="fitImage"
        gutter={[34, 34]}
        Image={
          <Col xs={24} sm={24} md={12} lg={12} className="fitImage">
            <img src={web} alt="about1" />
          </Col>
        }
        Body={
          <Col xs={24} lg={12}>
            <h4>Our Services</h4>
            <h1>Creative UI/UX design solutions</h1>
            <ul>
              <li>
                Stay relaxed, and let us solve your UI/UX problems through our
                technical proficiency. Our team can think critically and solve
                problems creatively to develop innovative solutions.
              </li>
              <li>
                Our mission is to achieve success and satisfaction for clients,
                which sets us apart in the industry.
              </li>
            </ul>
          </Col>
        }
      />



<Card
        className="fitImage"
        gutter={[34, 34]}
        Image={
          
          <Col lg={12} xs={24} >
            <img src={uiuximg} alt="about1" />
          </Col>
        } reverse
        Body={
          <Col lg={12} xs={24} >
            <h4>Our Services</h4>
            <h1>Creative UI/UX design solutions</h1>
            <ul>
              <li>
              Stay relaxed, and let us solve your UI/UX problems through our technical proficiency. Our team can think critically and solve problems creatively to develop innovative solutions. Our mission is to achieve success and satisfaction for clients, which sets us apart in the industry. 



              </li>
              <li>
              We are industry leaders, and we take pride in understanding your project’s needs and providing appropriate solutions for them. Join us, and we will guide your digital journey to excellence; also, we make sure of flawless experiences that engage the audience and drive quality results.
              </li>
            </ul>
          </Col>
        }
        
       
      />

      <div className={styles.serviceContainer + " " + styles.appDev}>
        <h1>Our specialized services</h1>
        <Row gutter={20} className={styles.web}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="Website Design"
              content={[
                "Whether you are a new business looking to build an online presence or an existing one, our expert web designers can create websites that match your specific goals. We craft elegant yet engaging websites, bringing you a vision of life pixel by pixel.",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8}>
            <PrimaryCard
              title="Application Design"
              content={[
                "In a digital world where impression matters, our exceptional application design ensures freshness in your digital life. With user-friendly navigation, fast-loading speed, and effortless functionality, we deliver a unique user experience.",
              ]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <PrimaryCard
              title="Graphical Design"
              content={[
                "Look no further if you want to boost brand identity or elevate digital graphics; our adaptive team crafts bespoke creations - a mixture of creativity and strategy. ",
              ]}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UXUI;
