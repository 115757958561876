const companyData = [
  { count: "10000+", description: "Line of Codes pushed weekly" },
  { count: "10+", description: "Projects Delivered" },
  { count: "20+", description: "Professional Developers" },
  { count: "3+", description: "Years of Experience" },
];

const solarCompanyData = [
  { count: "25000+", description: "Solar Projects" },
  { count: "80+", description: "Professional Engineers" },
  { count: "6+", description: "Years of Experience" },
];

const data = { companyData, solarCompanyData };
export default data;
