import React, { useState } from "react";
import styles from "./index.module.css";
import { ChevronIcon, CloseIcon, MenuIcon } from "../../assets/icons";
import serviceData from "../../pages/Home/servicesData";
import { NavLink } from "react-router-dom";

const MobileNavLinks = () => {
  const [show, setShow] = useState(false);
  const [showServices, setshowServices] = useState(false);

  function handleMenu() {
    setShow(false);
    setshowServices(false);
  }
  return (
    <div className={styles.mobileMenu}>
      <MenuIcon onClick={() => setShow(true)} />
      {show ? (
        <div className={styles.container}>
          <div className={styles.menu}>
            <div className={styles.closeIcon}>
              <CloseIcon onClick={() => setShow(!show)} />
            </div>
            <NavLink to="/" onClick={handleMenu}>
              <h6>Home</h6>
            </NavLink>

            <div className={styles.serviceNav}>
              <h6 onClick={() => setshowServices(!showServices)}>Services</h6>
              <ChevronIcon />
            </div>

            {showServices ? (
              <div className={styles.servicesContainerMenu}>
                <div className={styles.servicesContainer}>
                  <h4>IT Services</h4>
                  {serviceData.itServices.map((s) => (
                    <NavLink to={s.to} onClick={handleMenu}>
                      <h6 key={s.title}>&#x2022; {s.title}</h6>
                    </NavLink>
                  ))}
                </div>
                <div className={styles.servicesContainer} onClick={handleMenu}>
                  <h4>Solar Serices</h4>
                  {serviceData.solarServices.map((s) => (
                    <NavLink to={s.to}>
                      <h6 key={s.title}>&#x2022; {s.title}</h6>
                    </NavLink>
                  ))}
                </div>
              </div>
            ) : null}
            <NavLink to="about" onClick={handleMenu}>
              <h6>About Us</h6>
            </NavLink>
            <NavLink to="contactus" onClick={handleMenu}>
              <h6>Contact Us</h6>
            </NavLink>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default MobileNavLinks;
