import React from "react";
import { Carousel, Row } from "antd";
import { profile } from "../../assets";
import { StarIcon } from "../../assets/icons";
import styles from "./home.module.css";
function Clients() {
  return (
    <Carousel autoplay autoplaySpeed={1800} infinite>
      <div>
        <div className={styles.sliderContainer}>
          <img src={profile} alt="profile" className={styles.profile} />
          <ul>
            <li>
              We trusted TriventCad with our project and they did a marvellous
              job. Their web development services are better than the industry
              standards and they stand on their words when they say they will
              provide you complete customer satisfaction. Would definitely
              recommend
            </li>
          </ul>
          <Row style={{ margin: "20px 0" }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <StarIcon key={index} />
            ))}
          </Row>
          <h3>- Our World Energy</h3>
        </div>
      </div>
      <div>
        <div className={styles.sliderContainer}>
          <img src={profile} alt="profile" className={styles.profile} />
          <ul>
            <li>
              We were looking for a reliable source for web and app development
              services and it's safe to say TricentCad has done the job
              perfectly. Great staff, timely delivered services and quality
              assurance. We are happy with the service provided.
            </li>
          </ul>
          <Row style={{ margin: "20px 0" }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <StarIcon key={index} />
            ))}
          </Row>
          <h3>- Luxury</h3>
        </div>
      </div>

      <div>
        <div className={styles.sliderContainer}>
          <img src={profile} alt="profile" className={styles.profile} />
          <ul>
            <li>
              Great job done! Helpful staff and top quality services is what we
              were looking for and happy to have collaborated with them. Amazing
              quality work done in ui/ux design, ai, telecommunication and more.
            </li>
          </ul>
          <Row style={{ margin: "20px 0" }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <StarIcon key={index} />
            ))}
          </Row>
          <h3>- Mobileum</h3>
        </div>
      </div>

      <div>
        <div className={styles.sliderContainer}>
          <img src={profile} alt="profile" className={styles.profile} />
          <ul>
            <li>
              Loved working with TriventCad! Harding working team that helped us
              get the best IT services. Great with strict deadlines without
              jeopardising the quality of the services delivered, will recommend
              with utmost confidence.
            </li>
          </ul>
          <Row style={{ margin: "20px 0" }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <StarIcon key={index} />
            ))}
          </Row>
          <h3>- JWB</h3>
        </div>
      </div>

      <div>
        <div className={styles.sliderContainer}>
          <img src={profile} alt="profile" className={styles.profile} />
          <ul>
            <li>
              Working with TriventCad has been an outstanding experience! The
              team is hardworking, dedicated, and delivers top-notch IT
              services. They consistently met our strict deadlines without
              compromising quality. I highly recommend TriventCad with complete
              confidence.
            </li>
          </ul>
          <Row style={{ margin: "20px 0" }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <StarIcon key={index} />
            ))}
          </Row>
          <h3>- Joytide</h3>
        </div>
      </div>
      <div>
        <div className={styles.sliderContainer}>
          <img src={profile} alt="profile" className={styles.profile} />
          <ul>
            <li>
              TriventCad exceeded our expectations! The team's commitment and
              expertise ensured that our IT needs were met with precision and
              efficiency. Even under tight deadlines, they delivered exceptional
              results. I highly recommend TriventCad for anyone seeking reliable
              and high-quality IT services.
            </li>
          </ul>
          <Row style={{ margin: "20px 0" }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <StarIcon key={index} />
            ))}
          </Row>
          <h3>- ImageLab Med Spa</h3>
        </div>
      </div>
      <div>
        <div className={styles.sliderContainer}>
          <img src={profile} alt="profile" className={styles.profile} />
          <ul>
            <li>
              TriventCad is a true partner in delivering excellence. Their team
              is incredibly responsive and committed to providing the best IT
              solutions. Despite the tight timelines, they maintained
              high-quality standards throughout. I would confidently recommend
              TriventCad to anyone looking for top-tier IT services.
            </li>
          </ul>
          <Row style={{ margin: "20px 0" }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <StarIcon key={index} />
            ))}
          </Row>
          <h3>- Wolf River Electric</h3>
        </div>
      </div>
    </Carousel>
  );
}
export default Clients;
