const itServices = [
  {
    title: "Web Development",
    description:
      "Want to boost your online presence and fuel organic growth? Get in touch with web developers who have honed their skills for years.",
    icon: "WindowIcon",
    to: "/services/web-development",
  },
  {
    title: "App Development",
    description:
      "Design a user-friendly interface and simple-to-use application from our application developer team and Gain a chance to stand out from the crowded market.  ",
    icon: "AppIcon",
    to: "/services/app-development",
  },
  // {
  //   title: "Digital Marketing",
  //   description:
  //     "Digital marketing engages targeted audiences with products or services through the use of online platforms and technologies, resulting in business growth...",
  //   icon: "WindowIcon",
  // },
  {
    title: "Telecommunication",
    description:
      "You have lots of data to transfer in a single day. Quickly transfer your essential information globally with our 4g/5g network services.",
    icon: "TeleIcon",
    to: "/services/tele-communication",
  },
  {
    title: "Database Design & Development",
    description:
      "Are we facing storage issues? No worries, we are here with our Database design service that develops data storage, hence safely keeping your data stored in the database.",
    icon: "CRMIcon",
    to: "/services/database",
  },
  {
    title: "UI/UX Services",
    description:
      "All we want is to make human life easier, and that's why we design experiences that make human life simple.",
    icon: "UiIcon",
    to: "/services/ux-ui",
  },
  {
    title: "Artificial Intelligence",
    description:
      "Get your work done quickly and effectively with the support of our AI services.",
    icon: "AIIcon",
    to: "/services/ai",
  },
  // {
  //   title: "CRM Services",
  //   description:
  //     "Techniques and instruments for improving communications, maintaining relationships with customers, and streamlining company operations...",
  //   icon: "CRMIcon",
  // },
];

const solarServices = [
  {
    title: "Solar Permit Designs",
    description:
      "Solar permit designs refer to the detailed drawings and documentation required to obtain permits for solar energy system installations.",
    icon: "SolarIcon1",
    to: "/services/solar-services#one",
  },
  {
    title: "Solar Sales Proposals",
    description:
      "Solar sales proposals are documents prepared by solar energy companies to present their products and services to potential customers.",
    icon: "SolarIcon2",    
    to: "/services/solar-services#two",
  },
  {
    title: "Solar Preliminary Designs",
    description:
      "These designs provide a basic outline of the proposed solar installation, including the placement of solar panels, inverters, and other components",
    icon: "SolarIcon3",    
    to: "/services/solar-services#three",
  },
  {
    title: "Engineering Stamps",
    description:
      "Preliminary designs may also include information about the system's capacity, orientation, and potential shading issues. While not as detailed ",
    icon: "SolarIcon4",
    to: "/services/solar-services#four",
  },
  {
    title: "As Built Drawing",
    description:
      "In the context of solar energy systems, as-built drawings would depict the actual layout and configuration",
    icon: "SolarIcon5",
    to: "/services/solar-services#five",
  },
  {
    title: "CAD Conversion",
    description:
      "This conversion can involve various tasks, such as digitizing hand-drawn sketches, converting raster images",
    icon: "SolarIcon6",
    to: "/services/cad-conversion",
  },
];
const serviceData = { itServices, solarServices };

export default serviceData;
