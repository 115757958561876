import React from "react";
import styles from "./containers.module.css";
function BodyContainer({ children, bgImage, fullHeight = false }) {
  const _class = `${styles.container} + ${
    fullHeight ? "" : " " + styles.containerSm
  }`;
  return (
    <div
      className={_class}
      style={{
        background:
          "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%), url(" +
          bgImage +
          ") center/cover",
      }}
    >
      {children}
    </div>
  );
}

export default BodyContainer;
