import React, { useEffect, useState } from "react";
import AnimatedNumber from "animated-number-react";
import { Link } from "react-router-dom";
import {
  PrimaryButton,
  SecondaryButton,
  BodyContainer,
} from "../../components";
import { Col, Row } from "antd";
import {
  client1,
  client2,
  client3,
  experience,
  feedback,
  home,
  imagelabs,
  joy,
  wolfRiver,
} from "../../assets";
import { client4, solar1, solar2, experience1 } from "../../assets";
import { experience2 } from "../../assets";
import { gradient, group } from "../../assets";
import Services from "./Services";
import Card from "../../components/cards/Card";
import data from "../../data/companyData";

// Css
import styles from "./home.module.css";
import tagLines from "../../data/tagLines";
import Clients from "./Clients";

const { companyData, solarCompanyData } = data;

function Home() {
  const [currentTagLine, setCurrentTagLine] = useState(0);
  const [animationName, setAnimationName] = useState("bouncy");
  const [toggleCompanyData, setToggleCompanyData] = useState(true);
  const formatValue = (value) => value.toFixed(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTagLine((currentTagLine + 1) % tagLines.length);
      setAnimationName("scale");
      setToggleCompanyData(!toggleCompanyData);
    }, 3000);
    return () => clearInterval(interval);
  }, [currentTagLine, toggleCompanyData]);

  return (
    <>
      <BodyContainer bgImage={home} fullHeight>
        <section className="cd-intro" key={tagLines[currentTagLine]}>
          <div className={`cd-intro-content  ${animationName}`}>
            <div className={styles.tagLineContainer}>
              <h1>{tagLines[currentTagLine]}</h1>
            </div>
          </div>
        </section>

        <section className="cd-intro">
          <div className="cd-intro-content bouncy">
            <p>
              Empowering Your Digital Canvas: Where Services Converge for
              Enhanced Connectivity and Growth
            </p>
          </div>
        </section>

        <section className="cd-intro">
          <div className="cd-intro-content bouncy">
            <div className="action-wrapper">
              <Row gutter={24} justify="center">
                <Col className="cd-btn main-action">
                  <a href="#services">
                    <PrimaryButton title="EXPLORE SERVICES" />
                  </a>
                </Col>
                <Col className="cd-btn main-action">
                  <Link to="contactUs">
                    <SecondaryButton title="Get In Touch" />
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </BodyContainer>

      <div className={styles.carouselContainer}>
        <div className={styles.companyDataContainer}>
          <section className="cd-intro" style={{ width: "100%" }}>
            <div className="cd-intro-content bouncy" key={toggleCompanyData}>
              {toggleCompanyData ? (
                <Row
                  gutter={[8, 16]}
                  {...{ justify: "center", align: "middle" }}
                  className="animate"
                >
                  {companyData.map((item) => (
                    <Col
                      key={item.description}
                      {...{ xs: 12, sm: 12, md: 6, lg: 6 }}
                      className={styles.companyItem}
                    >
                      <h2>
                        <AnimatedNumber
                          value={item.count}
                          formatValue={formatValue}
                          duration={animationName === "scale" ? 0 : 2000}
                        />{" "}
                        +
                      </h2>

                      <h6>{item.description}</h6>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row
                  gutter={[8, 16]}
                  {...{ justify: "center", align: "middle" }}
                  className="animate"
                >
                  {solarCompanyData.map((item) => (
                    <Col
                      key={item.description}
                      {...{ xs: 12, sm: 12, md: 6, lg: 6 }}
                      className={styles.companyItem}
                    >
                      <h2>
                        <AnimatedNumber
                          value={item.count}
                          formatValue={formatValue}
                          duration={animationName === "scale" ? 0 : 2000}
                        />{" "}
                        +
                      </h2>
                      <h6>{item.description}</h6>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </section>
        </div>
      </div>

      {/* Experience */}
      <Card
        Image={
          <Col
            xs={24}
            lg={12}
            className={styles.imagesStack}
            data-aos="fade-right"
          >
            <img src={experience1} alt="expereince" />
            <img src={experience2} alt="group" />
          </Col>
        }
        Body={
          <Col xs={24} lg={12} data-aos="fade-left">
            <h4>Get to know</h4>
            <h1>About Us</h1>
            <ul>
              <li>
                Welcome to TriventCad, where you will meet our innovative
                services. Established 3+ years ago, and since then, TriventCad
                has always been ahead for our revolutionizing innovations and
                services with our unmatchable expertise in developing
                competitive web and mobile applications. Not only do we love to
                code, but we also unravel business issues and solve them
                efficiently.
              </li>
              <li>
                Innovations and problem-solving capabilities are at the heart of
                all we do. With a team of highly creative, skilled professionals
                and devotion to excellence, TriventCad Services is your trusted
                partner at every business step! Join us to enhance your business
                potential now!
              </li>
            </ul>
          </Col>
        }
      />

      {/* Technologies */}
      <Services />

      {/* Black Tape */}
      <Row className={styles.tapeContainer}>
        <Col sm={24} lg={8} flex={1} style={{ textAlign: "right" }}>
          <h4>We Completed</h4>
          <h1>25000+</h1>
          <span>CAD DESIGNS</span>
        </Col>
        <Col sm={24} lg={14}>
          <h4>
            "We've completed 25000+ solar CAD designs and 10+ Software
            development projects, showcasing our team's dedication in web
            development and software solutions. Grateful for our clients' trust,
            we're driven to set new standards for excellence."
          </h4>
        </Col>
      </Row>

      <Card
        Image={
          <Col
            xs={24}
            lg={12}
            className={styles.imagesStack}
            data-aos="fade-left"
          >
            <img src={solar1} alt="expereince" />
            <img src={solar2} alt="group" />
          </Col>
        }
        reverse
        Body={
          <Col xs={24} lg={12} data-aos="fade-right">
            <h4>Solar Services</h4>

            <h1>Solar and CAD services </h1>
            <h3 style={{ fontSize: "16px" }}>
              ENERGISE YOUR FUTURE WITH OUR SOLAR SERVICES AND CAD!
            </h3>
            <ul>
              <li>
                Unseal the power of solar energy with the help of our
                competitive solar services and CAD solutions. Our creative team
                of experts ensures a flawless adaptation, renewable energy and
                be a guide for your solar business journey at all levels. With
                accurate CAD design our team provides optimum solutions which
                best suits your unique needs and requirements.
              </li>
              <li>
                We support all types of projects whether it's homeowner
                business, social project or large scale business project, our
                team provides top grade service and also assists you at your
                steps.
              </li>
              <li>
                Make the world free of pollution, utilise the sun's energy and
                join with us in the green revolution with our trustworthy solar
                solutions and CAD designs. Join us and create a sustainable and
                brighter future. Get in touch with us now!
              </li>
            </ul>
          </Col>
        }
      />

      <Card
        className="colored"
        Image={
          <Col
            xs={24}
            lg={12}
            className={styles.imagesStack}
            data-aos="fade-up"
            data-aos-delay={400}
          >
            <img src={experience} alt="expereince" />
            <img src={group} alt="group" />
          </Col>
        }
        Body={
          <Col xs={24} lg={12} data-aos="fade-down" data-aos-delay={700}>
            <h4> Get to know</h4>
            <h1>We are here to IT Solution with 20 years of experience</h1>
            <ul>
              <li>
                You can have any amount of paths for any layer properties. More
                than that, you can have any amount of layers attached to one
                path.
              </li>
              <li>
                First, let's talk a little about what tool we will use. You can
                use presets animation, keyframes animation, paths animation or
                link animation for layer properties. We will work with paths.
                First, let's talk a little about what tool we will use. You can
                use presets animation.
              </li>
              <li>
                You can animate objects along the path. They can rotate
                according to the path curvature and have X, Y and Rotation
                offsets.
              </li>
            </ul>
          </Col>
        }
      />

      {/* <Card
        className="testimonials"
        Image={
          <Col sm={24} lg={12} className={styles.imageStackContainer}>
            <div className={styles.imageStack}>
              <img src={feedback} alt="expereince" />
              <img src={gradient} alt="group" />
            </div>
          </Col>
        }
        reverse
        Body={
          <Col sm={24} lg={12}>
            <h4>Testimonial</h4>
            <h1>What Our Client Sayss</h1>
            <Clients />
          </Col>
        }
      /> */}

      {/* <div className={styles.clients}>
        <Row justify={"center"}>
          <Col>
            <h1> Our Clients</h1>
          </Col>
        </Row>

        <div className="slide-container">
          <div className="slide-item-container">
            <div className="slide">
              <div className="center">
                <img src={client2} alt="client1" />
                <img src={client3} alt="client2" />
                <img src={joy} alt="client4" />
                <img src={client1} alt="client3" />
                <img src={client4} alt="client4" />
                <img src={imagelabs} alt="client4" />
                <img src={wolfRiver} alt="client4" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Home;
