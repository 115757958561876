import React from "react";
import styles from "./about.module.css";
import { Col, Row } from "antd";
import { about1, about2, about } from "../../assets";
import Card from "../../components/cards/Card";
import BodyContainer from "../../components/containers/BodyContainer";
import data from "../../data/companyData";
const { companyData } = data;

function About() {
  return (
    <>
      <BodyContainer bgImage={about}>
        <h1>
          Know <span>About</span> Us
        </h1>
      </BodyContainer>
      <Card
        className="fitImage"
        gutter={[34, 34]}
        Image={
          <Col xs={24} sm={24} md={12} lg={10}>
            <img src={about2} alt="about1" />
          </Col>
        }
        Body={
          <Col xs={24} sm={24} md={12} lg={14}>
            <h4>Get to know</h4>
            <h1>
              Bespoke Software Solutions: Take off and stay ahead of digital
              innovations.{" "}
            </h1>
            <ul>
              <li>
                Since its establishment, TriventCad has been a top choice for
                its end-to-end bespoke solutions driven by cutting-edge tools,
                technology stacks, and methodologies. We are known for our
                super-fast IT services like Web Development, App Development,
                Database Design & Development, UI/UX Services, Artificial
                Intelligence, and Telecommunication Services. With a piece of
                creative work and putting ideas into action, TriventCad will
                always be the factor in your digital growth.
              </li>
            </ul>
          </Col>
        }
      />

      <Card
        className="fitImage"
        gutter={[34, 34]}
        Image={
          <Col xs={24} sm={24} md={12} lg={10}>
            <Row justify={{ lg: "end", xs: "center" }}>
              <img src={about1} alt="about1" />
            </Row>
          </Col>
        }
        reverse
        Body={
          <Col xs={24} sm={24} md={12} lg={14}>
            <h4>Vision Mission</h4>
            <h1>
              Crafting seamless digital experiences with innovative web
              solutions for businesses."
            </h1>
            <ul>
              <li>
                We are born to make you stand out in the digital landscape and
                strive to drive growth and sustained success to you. With years
                of experience in the domain, our genius web & software
                developers deliver visually stunning and robust web designs and
                mobile applications. As the top digital innovation agency, we
                pledge to deliver superior outcomes distinguished by quality,
                punctuality, and cost-effectiveness.
              </li>
            </ul>
          </Col>
        }
      />

      <div className={styles.companyDataContainer}>
        <Row justify={"center"} gutter={[10, 20]}>
          {companyData.map((item) => (
            <Col key={item.description} flex={1} className={styles.companyItem}>
              <h2>{item.count}</h2>
              <h6>{item.description}</h6>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default About;
