import React from "react";
import styles from "./contact.module.css";
import ContactForm from "./ContactForm";
import { Row, Col } from "antd";
import {
  CallIcon,
  EmailIcon,
  GlobeIcon,
  LocationIcon,
} from "../../assets/icons";
import BodyContainer from "../../components/containers/BodyContainer";
import { about } from "../../assets";

function ContactUs() {
  const emailIconProps = {
    width: 24,
    height: 24,
    stroke: "#0ac79a",
    fill: "#0ac79a",
    strokeWidth: 0.1,
  };

  return (
    <>
      <BodyContainer bgImage={about}>
        <h1>
          <span>Contact</span> Us
        </h1>
      </BodyContainer>

      <div className={styles.contactContainer}>
        <h1>Send Us A Message</h1>
        <Row gutter={{ xs: 0, sm: 0, md: 60, lg: 60, xl: 60 }} align={"middle"}>
          <Col xs={24} md={12} lg={12} xl={12}>
            <ContactForm />
          </Col>
          <Col xs={24} md={12} lg={12} xl={10}>
            <Row>
              <Col className={styles.getInTouch} xs={24} md={24} lg={18}>
                <h2>Get In Touch</h2>
                <Row gutter={10} style={{ marginTop: 20 }}>
                  <Col>
                    <CallIcon />
                  </Col>
                  <Col>
                    <h4>Call Us</h4>
                    <h6 style={{ fontWeight: 400 }}>+91 981 779 1221</h6>
                  </Col>
                </Row>
                <Row gutter={10} style={{ marginTop: 20 }}>
                  <Col>
                    <EmailIcon {...emailIconProps} />
                  </Col>
                  <Col>
                    <h4>Email Us</h4>
                    <h6 style={{ fontWeight: 400 }}>info@triventcad.com</h6>
                  </Col>
                </Row>

                <Row gutter={10} style={{ marginTop: 20 }}>
                  <Col>
                    <LocationIcon {...emailIconProps} />
                  </Col>
                  <Col span={20}>
                    <h4>Address</h4>
                    <h6 style={{ fontWeight: 400 }}>
                      Third Floor, Dhaka Tower, GMS Rd, Dehradun, Uttarakhand -
                      248171, INDIA
                    </h6>
                  </Col>
                </Row>

                <Row gutter={10} style={{ marginTop: 20 }}>
                  <Col>
                    <GlobeIcon {...emailIconProps} />
                  </Col>
                  <Col>
                    <h4 style={{ textAlign: "left", fontWeight: 600 }}>
                      Website
                    </h4>
                    <h6 style={{ fontWeight: 400 }}>www.triventcad.com</h6>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ContactUs;
